import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SeoHelper";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" description="It looks like this page was not found. Sorry about that." />
    <div className="container p-8 m-auto text-center">
      <h1 className="text-3xl font-semibold">Page not found</h1>
      <p>We&apos;re sorry, but we cannot find this page.</p>
    </div>
  </Layout>
);

export default NotFoundPage;
